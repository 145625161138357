import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import StorageSrvc from '../StorageSrvc';
import { STORAGE_SRVC_KEYS } from '../../config/constants/AppStrings';

export const QUERIES = {
  GET_STORED_PROFILE: () => useQuery({
    queryKey: [AUTH_CONSTANTS.QUERY_KEYS.STORAGE_PROFILE],
    queryFn: () => {
      return StorageSrvc.getItem(STORAGE_SRVC_KEYS.CURRENT_USER);
    }
  })

}

export const MUTATIONS = {
  SIGNIN_USER: (queryClient?: QueryClient) => useMutation({
    mutationFn: (payload: any) => {
      return HttpSrvc.post(`${AUTH_CONSTANTS.USER_LOGIN_API}`, payload)
    },
    onSuccess: (data: any) => {

    }
  }),
  LOGOUT: (queryClient?: QueryClient) => useMutation({
    mutationFn: async () => {
      // const deviceToken = await StorageSrvc.getItem(StorageSrvcKeys.DEVICE_TOKEN_KEY)
      return HttpSrvc.post(`${AUTH_CONSTANTS.LOGOUT}`, {})
    },
    onSettled: () => {
      StorageSrvc.clear();
      queryClient?.invalidateQueries({ queryKey: [AUTH_CONSTANTS.QUERY_KEYS.STORAGE_PROFILE] });
      location.reload();
    }
  }),
  SIGNUP_USER: () => useMutation({
    mutationFn: (payload: any) => {
      return HttpSrvc.post(`${AUTH_CONSTANTS.SIGNUP_USER_API}`, payload)
    }
  }),
}

export const AUTH_CONSTANTS = {
  QUERY_KEYS: {
    STORAGE_PROFILE: 'STORAGE_PROFILE'
  },
  USER_LOGIN_API: `/auth/signin`,
  LOGOUT: `/auth/logout`,
  SIGNUP_USER_API: `/auth/signup`

}
