import AppStrings from "../config/constants/AppStrings";

class StorageSrvc {

  async setItem(key: string, data: string | {}, expiryInMilliseconds: number = 0) {
    key = AppStrings.STORAGE_PREFIX + key;
    const payload: any = {
      data
    };

    if (expiryInMilliseconds) {
      payload.expiry = Date.now() + expiryInMilliseconds;
    }

    await localStorage.setItem(key, JSON.stringify(payload));
  }

  async getItem(key: string) {
    key = AppStrings.STORAGE_PREFIX + key;
    const item = await localStorage.getItem(key);
    if (item) {
      try {
        const payload = JSON.parse(item);
        if (payload.expiry && payload.expiry < Date.now()) {
          localStorage.removeItem(key);
          return null;
        }
        return payload.data;
      } catch (err) {
        return item;
      }
    }
    return null;
  }

  async removeItem(key: string) {
    key = AppStrings.STORAGE_PREFIX + key;
    return await localStorage.removeItem(key);
  }

  async getItemsList() {
    return Object.keys(localStorage);
  }

  async clear() {
    const keys = await this.getItemsList();
    keys.map(async (key) => {
      localStorage.removeItem(key);
    });
  }
}

export default new StorageSrvc();