import axios from "axios";
import CONSTANTS from "../config/constants";
import FormData from "form-data";
import StorageSrvc from "./StorageSrvc";
import { STORAGE_SRVC_KEYS } from "../config/constants/AppStrings";

class HttpSrvc {

  baseURL: string;
  axiosInstance: any;
  timeout!: number;

  constructor(baseUrl: string, timeout: number) {
    this.baseURL = baseUrl;
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      timeout: timeout
    });

    this.axiosInstance.interceptors.request.use((config: any) => this.getHeaderConfig(config), function (error: any) {
      console.error(error);      
    });

    this.axiosInstance.interceptors.response.use((response: any) => response, 
    (error: any) => {
      if (error?.response?.status === 401) {
        StorageSrvc.clear();
      }
      return Promise.reject(error);
    });
    
  }

  private async getHeaderConfig(headerConfig: any) {
    const authToken = await StorageSrvc.getItem(STORAGE_SRVC_KEYS.AUTH_TOKEN);
    if (authToken) {
      headerConfig.headers["Authorization"] = `Bearer ${authToken}`;
    }

    return headerConfig;
  };

  private errorMessageHandler(error: any) {
    console.debug(error);
    if (error.code === "ECONNABORTED") {
      throw Error("Network Connection Timeout");
    }
    throw error;    
  }

  public async postFormData(path: string, data: any, queryParams?: any, headers?: any) {
    try {
      const config = {
        method: "post",
        url: this.baseURL + path,
        params: queryParams,
        headers: { ...headers, "Content-Type": "multipart/form-data" },
        data: data
      };
      const response = await this.axiosInstance.request(config);
      return response.data;
    }
    catch (err: any) {
      this.errorMessageHandler(err);
    }
  }

  public async post(path: string, data: any, queryParams?: any, headers?: any) {
    try {
      const config = {
        method: "post",
        url: path,
        params: queryParams,
        headers,
        data
      };
      const response = await this.axiosInstance.request(config);
      return response.data;
    }
    catch (err: any) {
      this.errorMessageHandler(err);
    }
  }

  public async put(path: string, data: any, queryParams?: any, headers?: any) {
    try {
      const config = {
        method: "put",
        url: path,
        params: queryParams,
        headers,
        data
      };
      const response = await this.axiosInstance.request(config);
      return response.data;
    }
    catch (err: any) {
      this.errorMessageHandler(err);
    }
  }

  public async get(path: string, queryParams?: any, headers?: any) {
    try {
      const response = await this.axiosInstance.request(
        {
          method: "get",
          url: path,
          params: queryParams,
          headers,
        }
      );
      return response.data
    }
    catch (err) {
      this.errorMessageHandler(err);
    }
  }
}

export default new HttpSrvc(CONSTANTS.ENV_CONSTANTS.BASE_URL, CONSTANTS.ENV_CONSTANTS.HTTP_TIMEOUT);