import React, { createContext, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import CarbonpaperLogo from "./../images/carbonpaper-full-logo-cropped.svg";
import CarbonpaperLogoOnly from "./../images/carbonpaper-logo.svg";

import QuerySrvc from '../services/queries/QuerySrvc';
import { getProfilePic, getUserNameOrEmail } from '../services/utils';
import { ChevronDown } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import Toast, { ToastHandle } from './Toast';
import { ToastContext, GlobalContext } from './Contexts';
import AppDownloadBanner from './AppDownloadBanner';


const AuthenticatedLayout: React.FC = () => {

  const queryClient = useQueryClient();

  const toastRef = useRef<ToastHandle>(null);

  const [showDownloadAppbar, setShowDownloadAppbar] = React.useState(true);

  const showSuccessToast = (message: string) => {
    toastRef.current?.show(message, 'success');
  };

  const showErrorToast = (message: string) => {
    toastRef.current?.show(message, 'error');
  };

  const showDownloadAppBar = () => {
    setShowDownloadAppbar(true);
  }

  const { data: storedUserProfile, isSuccess: isStoredUserProfileFetchSuccess, isError: isStoredUserProfileFetchError,
    isFetching: isStoredUserProfileFetching, refetch: refetchStoredUserProfile } = QuerySrvc.QUERIES.AUTH.GET_STORED_PROFILE();

  const logoutMutation = QuerySrvc.MUTATIONS.AUTH.LOGOUT(queryClient);

  const profilePicUrl = getProfilePic(storedUserProfile?.profilePic)

  const username = getUserNameOrEmail(storedUserProfile);

  return (
    <GlobalContext.Provider value={{ showDownloadAppBar }}>
      <ToastContext.Provider value={{ showSuccessToast, showErrorToast }}>
        <div className='flex flex-col  min-h-screen h-full bg-brand-darkgray'>
          <div className="flex navbar bg-brand-darkgray sticky top-0 z-40 border-b-2 border-brand-fadegray">
            <div className="flex-1">
              <a className="w-60 bg-brand-darkgray text-xl p-4 hidden md:flex">
                <img src={CarbonpaperLogo} alt="Carbon Paper Logo" className="w-full" />
              </a>
              <a className="w-60 bg-brand-darkgray text-xl px-4 flex md:hidden overflow-hidden">
                <img src={CarbonpaperLogoOnly} alt="Carbon Paper Logo" className="h-6" />
              </a>
            </div>
            {
              storedUserProfile &&
              <div className="flex-none">
                <div className="dropdown dropdown-end">
                  <div tabIndex={0} role="button" className="btn btn-ghost pr-0 border-0 hover:bg-brand-fadegray/5">
                    {
                      profilePicUrl &&
                      <div className='avatar btn-circle btn border-0 bg-transparent'>
                        <div className="w-8 rounded-full ">
                          <img
                            alt={username}
                            src={profilePicUrl} />
                        </div>
                      </div> ||
                      <div className="avatar placeholder">
                        <div className="bg-neutral text-neutral-content w-8 rounded-full">
                          <span className="text-xl">{username[0]}</span>
                        </div>
                      </div>}
                    <div className='mx-2 flex-col h-7 hidden sm:flex'>
                      <div className='flex flex-1 capitalize'>
                        {username}
                      </div>
                      <div className='flex flex-1 justify-end pt-1 lowercase font-light'>
                        {storedUserProfile.emailId} <ChevronDown size={16} />
                      </div>
                    </div>
                  </div>
                  <ul
                    tabIndex={0}
                    className="menu menu-sm dropdown-content bg-brand-darkgray rounded-box z-[1] mt-3 w-52 p-2 shadow">
                    {/* <li>
                  <a className="justify-between">
                    Profile
                    <span className="badge">New</span>
                  </a>
                </li>
                <li><a>Settings</a></li> */}
                    <li onClick={() => { logoutMutation.mutate() }}><a>Logout</a></li>
                  </ul>
                </div>
              </div>
            }
          </div>
          <main className='flex flex-col z-30 overflow-auto'>
            <Outlet />
          </main>
          <div className='z-50'>
            <Toast ref={toastRef} />
            <AppDownloadBanner toggleSwitch={showDownloadAppbar} />
          </div>
        </div>
      </ToastContext.Provider>
    </GlobalContext.Provider>
  );
};

export default AuthenticatedLayout;