import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import LogoAndText from "../LogoAndText";
import Field from "../form/Field";
import { Alert } from "../form/Alert";
import { FormFieldObject, IFormField, ISigninRequest, ISignupRequest, IVerifyOtpRequest } from "./model/LoginModel";
import { IdCheckRequest, SigninRequest, SendOtpRequest, VerifyOtpRequest, SignupRequest } from "./api";
import { SigninWithOtpRequest } from "./api/SigninWithOtpReques";
import { useTimer } from "../../services/useTimer";
import querySrvc from "../../services/queries/QuerySrvc";
import GoogleSignIn from "../GoogleSignIn";
import StorageSrvc from "../../services/StorageSrvc";
import { STORAGE_SRVC_KEYS } from "../../config/constants/AppStrings";

interface UserCheck {
  exists: boolean;
  medium: string;
}

enum LoginSteps {
  idCheck = "idCheck",
  verifyOtp = "verifyOtp",
  signin = "siginin",
  signup = "signup",
  otpSignin = "otpSignin",
}

export const Login = () => {
  const signinUserMutation = querySrvc.MUTATIONS.AUTH.SIGNIN_USER();

  const [idCheck, setIdCheck] = useState<UserCheck>({ exists: false, medium: "" });
  const [loginStep, setLoginStep] = useState<LoginSteps>(LoginSteps.idCheck);
  const [otpVerificationId, setOptVerificationId] = useState<string>("");
  const [formFields, setFormFields] = useState<IFormField[]>([]);
  const [formError, setFormError] = useState<string | null>(null);
  const [resendTime, setResendTime] = useTimer({ startTime: 5 });
  const navigate = useNavigate();

  useEffect(() => {
    if (signinUserMutation.status === 'success') {
      const onSigninSuccess = async () => {
        signinUserMutation.data?.authToken && await StorageSrvc.setItem(STORAGE_SRVC_KEYS.AUTH_TOKEN, signinUserMutation.data?.authToken);
        signinUserMutation.data?.refreshToken && await StorageSrvc.setItem(STORAGE_SRVC_KEYS.REFRESH_TOKEN, signinUserMutation.data?.refreshToken);
        // setCurrentUser(signinUserMutation.data);
        // setEnableProfileTokenRefresh(true);
      }
      onSigninSuccess();
    }
    else if (signinUserMutation.status === 'error') {
      // setSnackbarMessage(getErrorStringFromSelector(signinUserMutation.error));
      // setSigninInProgress(false);
    }
  }, [signinUserMutation.status]);

  const schemaObject = Object.fromEntries(formFields.map((field: IFormField) => [field.name, field.errorType]));
  const validationSchema = Yup.object().shape(schemaObject);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const sendOtp = async () => {
    const response = await SendOtpRequest({ emailOrPhone: getValues("emailOrPhone") });
    setOptVerificationId(response.id);
  };

  const checkId = async (data: { emailOrPhone: string }) => {
    const response = await IdCheckRequest(data.emailOrPhone);
    setIdCheck(response);
    if (response.exists) {
      setLoginStep(LoginSteps.signin);
    } else {
      sendOtp();
      setLoginStep(LoginSteps.verifyOtp);
    }
  };

  const signin = async (data: ISigninRequest) => {
    try {      
      const response = await signinUserMutation.mutate(data)
      setFormError(null);
      // dispatch(setUserDetails(response));
      // localStorage.setItem("userDetails", JSON.stringify(response));
      // navigate('/')
      // navigate("/bulk-upload");
    } catch (error: any) {
      setFormError(error.response.error);
    }
  };

  const otpSignin = async (data: IVerifyOtpRequest) => {
    const payLoadData = { id: otpVerificationId, otp: data.otp };
    try {
      const response = await SigninWithOtpRequest(payLoadData);
      setFormError(null);
      // dispatch(setUserDetails(response));
      navigate("/");
    } catch (error: any) {
      setFormError(error.response.error);
    }
  };

  const verifyOtp = async (data: IVerifyOtpRequest) => {
    const payLoadData = { id: otpVerificationId, otp: data.otp };
    try {
      const response = await VerifyOtpRequest(payLoadData);
      if (response) {
        setLoginStep(LoginSteps.signup);
        setFormError(null);
        const value = getValues("emailOrPhone");
        if (!idCheck.exists) {
          if (idCheck.medium === "EMAIL") {
            setValue("emailId", value);
          }
          if (idCheck.medium === "PHONE") {
            setValue("mobileNumber", value);
          }
        }
      }
    } catch (error: any) {
      setFormError(error.response.error);
    }
  };

  const signup = async (data: ISignupRequest) => {
    try {
      const payloadData = { ...data, id: otpVerificationId };
      const response = await SignupRequest(payloadData);
      // dispatch(setUserDetails(response));
      navigate("/");
    } catch (error: any) {
      setFormError(error.response.error);
    }
  };

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    switch (loginStep) {
      case LoginSteps.idCheck:
        checkId(data);
        break;
      case LoginSteps.signin:
        signin(data);
        break;
      case LoginSteps.verifyOtp:
        verifyOtp(data);
        break;
      case LoginSteps.signup:
        signup(data);
        break;
      case LoginSteps.otpSignin:
        otpSignin(data);
    }
  };

  const onClickSignInWithOtp = async (e: any) => {
    e.preventDefault();
    sendOtp();
    setLoginStep(LoginSteps.otpSignin);
  };

  useEffect(() => {
    switch (loginStep) {
      case LoginSteps.idCheck:
        setFormFields([FormFieldObject.emailOrPhone]);
        break;
      case LoginSteps.signin:
        setFormFields([{ ...FormFieldObject.emailOrPhone, disabled: true }, FormFieldObject.password]);
        break;
      case LoginSteps.verifyOtp:
      case LoginSteps.otpSignin:
        setFormFields([{ ...FormFieldObject.emailOrPhone, disabled: true }, FormFieldObject.otp]);
        setResendTime(5);
        break;
      case LoginSteps.signup:
        if (idCheck.medium === "EMAIL") {
          setFormFields([{ ...FormFieldObject.emailId, disabled: true }, FormFieldObject.password, FormFieldObject.firstName, FormFieldObject.lastName, FormFieldObject.mobileNumber]);
        } else {
          setFormFields([FormFieldObject.emailId, FormFieldObject.password, FormFieldObject.firstName, FormFieldObject.lastName, { ...FormFieldObject.mobileNumber, disabled: true }]);
        }
    }
  }, [loginStep]);

  const getFormText = () => {
    let buttonText = "";
    let headerText = "";
    switch (loginStep) {
      case LoginSteps.idCheck:
        buttonText = "Continue";
        headerText = "Sign In/Sign Up";
        break;
      case LoginSteps.signin:
      case LoginSteps.otpSignin:
        buttonText = "Sign In";
        headerText = "Sign In";
        break;
      case LoginSteps.verifyOtp:
        buttonText = "Verify otp";
        headerText = "Verify Email/Number";
        break;
      case LoginSteps.signup:
        buttonText = "Sign Up";
        headerText = "Sign Up";
        break;
    }
    return {
      buttonText,
      headerText,
    };
  };

  const gSignSuccess = (res: any) => {
    console.log(res);
  }

  const gSignError = (err: any) => {
    console.log(err);
  };

  return (
    <div id="error-page" className="flex flex-col items-center w-full h-full bg-brand-darkgray text-brand-lightgray text-center">
      <div className="h-1/3 w-full sm:w-2/3 flex px-3 sm:p-0">
        <LogoAndText />
      </div>

      <div className="w-full h-2/3 flex justify-center">
        <div className="modal-box bg-brand-lightgray text-brand-darkgray flex-col px-20">
          <h3 className="font-bold text-lg">{getFormText().headerText}</h3>
          {formError && <Alert message={formError} />}
          <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
            {formFields.map((field: IFormField) => {
              return <Field key={field.id} type={field.type} id={field.id} name={field.name} label={field.label} required={field.required} register={register} value={watch(field.name)} disabled={field.disabled} error={errors[field.name]} />;
            })}
            {(loginStep === LoginSteps.verifyOtp || loginStep === LoginSteps.otpSignin) && (
              <div className="flex flex-row-reverse my-1">
                <button
                  className="enabled:opacity-100 disabled:opacity-60"
                  disabled={resendTime !== 0}
                  type="reset"
                  onClick={(e) => {
                    e.preventDefault();
                    sendOtp();
                    setResendTime(5);
                  }}
                >
                  Resend OTP {resendTime !== 0 && <span> in {resendTime} seconds</span>}
                </button>
              </div>
            )}
            <br />
            <div className="flex">
              <button type="submit" className="btn mx-auto capitalize bg-brand-darkgray text-brand-lightgray font-light text-xl disabled:text-brand-darkgray">
                {getFormText().buttonText}
              </button>
              {loginStep === LoginSteps.signin && (
                <button type="button" className="btn mx-auto capitalize bg-brand-darkgray text-brand-lightgray font-light text-xl disabled:text-brand-darkgray" 
                onClick={onClickSignInWithOtp}>
                  Sign In with OTP
                </button>
              )}
            </div>
          </form>
          <br /><br /><br />
          <hr />
          <br /><br /><br />

          <GoogleSignIn successLoginCallback={gSignSuccess} errorCallback={gSignError} />
        </div>
      </div>
    </div>
  );
};
