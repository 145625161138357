import React, { useEffect, useRef } from "react";
import httpSrvc from "../services/HttpSrvc";
import CONSTANTS from "../config/constants";

interface GoogleSignInProps {
  /* prompt_parent_id attribute sets the DOM ID of the container element. 
  If it's not set, the One Tap prompt is displayed in the top-right corner of the window. */
  prompt_parent_id?: string;
  successLoginCallback: (...args: any[]) => void;
  errorCallback: (...args: any[]) => void;
}

export default (props: GoogleSignInProps) => {

  const refEl = useRef<any>(0);

  const gsiSrc = "https://accounts.google.com/gsi/client";

  const loadScript = (src: string) => {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`))
        return resolve(src)
      const script = document.createElement('script')
      script.src = src
      script.onload = () => resolve(src)
      script.onerror = (err) => reject(err)
      document.body.appendChild(script)
    })
  }


  useEffect(() => {
    const handleCredentialResponse = async (credentials: any) => {
      try {
        const response = await httpSrvc.post("/auth/gauth", {credential: credentials.credential});
        return props.successLoginCallback(response);
      } catch (error) {
        console.log(error);
        return props.errorCallback(error);        
      }
      
    }

    async function initializeGSI() {
      let loadRes = null;
      try {
        loadRes = await loadScript(gsiSrc);
      } catch (error) {
        console.log(error);
        return props.errorCallback(error);
      }
      
      const google = window.google;

      if (google) {
        google.accounts.id.initialize({
          client_id: CONSTANTS.ENV_CONSTANTS.G_CLIENT_ID,
          callback: handleCredentialResponse,
          ux_mode: "popup",
          prompt_parent_id: props.prompt_parent_id,
          itp_support: false
        });
        const parent = refEl.current;
        google.accounts.id.renderButton(parent, {
          type: "standard",
          theme: "outline",
          shape: "pill",
          text: "signin_with",
          // size: "large",
          logo_alignment: "left"
        });
        google.accounts.id.prompt();
      }      
    }

    initializeGSI();

    return () => {
      const scriptTag = document.querySelector(`script[src="${gsiSrc}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, []);


  return (
    <div id="googleSignInButtonContainer" className="color-scheme-none flex justify-center" ref={refEl}></div>
  )
}