import * as React from "react";
import { BrowserRouter, createBrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./App";
import "./styles/styles.css";
import { Login } from "./components/login/Login";
import { store } from "./redux/store";
import ErrorPage from "./components/ErrorPage";
import { createRoot } from "react-dom/client";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import GoogleSignIn from "./components/GoogleSignIn";
import ContactUs from "./components/ContactUs";
import BulkPhotoUploader from "./components/BulkPhotoUploader";
import ItineraryViewer from "./components/itinerary/ItineraryViewer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DayList } from "./components/itinerary/DayList";
import AuthenticatedLayout from "./components/AuthenticatedLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App name="carbonpaper-web" />,
    errorElement: <ErrorPage />,
  },
  {
    path: "itinerary/:itineraryId",
    element: <ItineraryViewer />
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "terms-of-service",
    element: <TermsOfService />,
  },
  {
    path: "contact-us",
    element: <ContactUs />,
  },
  {
    path: 'bulk-upload',
    element: <BulkPhotoUploader />
  }
]);

const container = document.getElementById("approot");
const root = createRoot(container!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="" element={<App name="carbonpaper-web" />} />
            <Route path="login" element={<Login />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="bulk-upload" element={<BulkPhotoUploader />} />
            <Route element={<AuthenticatedLayout />} >
              <Route path="itinerary/:itineraryId/*" element={<ItineraryViewer />} />
            </Route>            
          </Routes>
        </BrowserRouter>
        {/* <RouterProvider router={router} /> */}
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
