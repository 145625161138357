import React, { useEffect, useState } from 'react';
import { X, Mail, Lock, ArrowRight, Loader, Check, Building, User } from 'lucide-react';
import GoogleSignIn from '../../GoogleSignIn';
import StorageSrvc from '../../../services/StorageSrvc';
import { STORAGE_SRVC_KEYS } from '../../../config/constants/AppStrings';
import QuerySrvc from '../../../services/queries/QuerySrvc';
import { getErrorStringFromObject } from '../../../services/utils';
import { motion, useTime, useTransform } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { AUTH_CONSTANTS } from '../../../services/queries/AuthApi';
import ForgotPassword from './ForgotPassword';

type AuthMode = 'login' | 'register' | 'forgot-password';

interface SignupForm {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  organization?: string;
  otp: string;
  signup?: string;
}

type SignupStep = 'email' | 'otp' | 'details' | 'success';


export default function AuthPanel({ content, className, callback, errorCallback }: any) {

  const queryClient = useQueryClient();

  const signinUserMutation = QuerySrvc.MUTATIONS.AUTH.SIGNIN_USER(queryClient);
  const sendOtpMutation = QuerySrvc.MUTATIONS.OPEN.SEND_OTP();
  const verifyOtpMutation = QuerySrvc.MUTATIONS.OPEN.VERIFY_OTP();
  const signupMutation = QuerySrvc.MUTATIONS.AUTH.SIGNUP_USER();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState<AuthMode>('login');
  const [email, setEmail] = useState(searchParams.get("emailId") || '');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState<string | null>(null);
  const time = useTime();
  const rotate = useTransform(time, [0, 4000], [0, 360], { clamp: false });
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [signupErrors, setSignupErrors] = useState<Partial<SignupForm>>({});
  const [lastSuccessfullOtpId, setLastSuccessfullOtpId] = useState<string>('');

  const [form, setForm] = useState<SignupForm>({
    email,
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    organization: '',
    otp: ''
  });

  const [signupStep, setSignupStep] = useState<SignupStep>('email');

  useEffect(() => {
    const checkUser = async () => {
      const user = await StorageSrvc.getItem(STORAGE_SRVC_KEYS.CURRENT_USER);
      if (user) {
        setCurrentUser(user);
      }
    }
    checkUser();

  }, [isOpen]);

  useEffect(() => {
    if (signinUserMutation.status === 'success') {
      const onSigninSuccess = async () => {
        signinUserMutation.data?.authToken && await StorageSrvc.setItem(STORAGE_SRVC_KEYS.AUTH_TOKEN, signinUserMutation.data?.authToken);
        signinUserMutation.data?.refreshToken && await StorageSrvc.setItem(STORAGE_SRVC_KEYS.REFRESH_TOKEN, signinUserMutation.data?.refreshToken);
        await StorageSrvc.setItem(STORAGE_SRVC_KEYS.CURRENT_USER, signinUserMutation.data);
        queryClient?.invalidateQueries({ queryKey: [AUTH_CONSTANTS.QUERY_KEYS.STORAGE_PROFILE] });
        setCurrentUser(signinUserMutation.data);
        callback(signinUserMutation.data);
        setIsOpen(false);
      }
      onSigninSuccess();
    }
    else if (signinUserMutation.status === 'error') {
      setAuthError(getErrorStringFromObject(signinUserMutation.error));
      errorCallback && errorCallback(signinUserMutation.error);
    }
  }, [signinUserMutation.status]);

  useEffect(() => {
    if (verifyOtpMutation.status === 'success') {
      setSignupStep('details');
      setSignupErrors({});
    }
    else if (verifyOtpMutation.status === 'error') {
      setSignupErrors({ otp: 'Invalid OTP. Please try again.' });
    }
  }, [verifyOtpMutation.status]);

  useEffect(() => {
    if (sendOtpMutation.status === 'success') {
      setLastSuccessfullOtpId(sendOtpMutation.data.id);
      setSignupStep('otp');
      setSignupErrors({});
    }
    else if (sendOtpMutation.status === 'error') {
      setSignupErrors({ email: 'Failed to send OTP. Please try again.' });
    }
  }, [sendOtpMutation.status]);

  useEffect(() => {
    if (signupMutation.status === 'success') {
      const onSignupSuccess = async () => {
        setSignupStep('success');
        setSignupErrors({});
        signupMutation.data?.authToken && await StorageSrvc.setItem(STORAGE_SRVC_KEYS.AUTH_TOKEN, signupMutation.data?.authToken);
        signupMutation.data?.refreshToken && await StorageSrvc.setItem(STORAGE_SRVC_KEYS.REFRESH_TOKEN, signupMutation.data?.refreshToken);
        await StorageSrvc.setItem(STORAGE_SRVC_KEYS.CURRENT_USER, signupMutation.data);
        queryClient?.invalidateQueries({ queryKey: [AUTH_CONSTANTS.QUERY_KEYS.STORAGE_PROFILE] });
        setCurrentUser(signupMutation.data);
        callback(signupMutation.data);
        setTimeout(() => {
          setIsOpen(false);
        }, 1000);
      }

      onSignupSuccess();
    }
    else if (signupMutation.status === 'error') {
      setSignupErrors({ signup: getErrorStringFromObject(signupMutation.error) || 'Failed to create account. Please try again.' });
    }
  }, [signupMutation.status]);

  const validateEmail = (email: string) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateEmail(form.email)) {
      setSignupErrors({ email: 'Please enter a valid email address' });
      return;
    }

    try {
      // API call to send OTP would go here
      // await sendOTP(form.email);
      sendOtpMutation.mutate({ emailOrPhone: form.email });
      // setSignupStep('otp');
      // setSignupErrors({});
    } catch (error) {
      setSignupErrors({ email: 'Failed to send OTP. Please try again.' });
    }
  };

  const handleOTPSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (form.otp.length !== 6) {
      setSignupErrors({ otp: 'Please enter a valid 6-digit OTP' });
      return;
    }

    try {
      verifyOtpMutation.mutate({ id: lastSuccessfullOtpId, otp: form.otp });
    } catch (error) {
      setSignupErrors({ otp: 'Invalid OTP. Please try again.' });
    }
  };

  const handleSignupSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: Partial<SignupForm> = {};

    if (!form.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!form.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!form.password) newErrors.password = 'Password is required';
    if (form.password !== form.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(newErrors).length > 0) {
      setSignupErrors(newErrors);
      return;
    }

    try {

      const payload = {
        emailId: form.email,
        password: form.password,
        firstName: form.firstName.trim(),
        lastName: form.lastName.trim(),
        ...(form.organization && { organisation: form.organization.trim() }),
        id: lastSuccessfullOtpId
      };

      signupMutation.mutate(payload);
    } catch (error) {
      setSignupErrors({ password: 'Failed to create account. Please try again.' });
    }
  };

  const handleInputChanges = (name: string, value: string) => {
    setForm(prev => ({ ...prev, [name]: value }));
    // Clear error when user starts typing
    if (signupErrors[name as keyof SignupForm]) {
      setSignupErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setAuthError('');
    signinUserMutation.mutate({ emailOrPhone: email, password });
  };

  const gSignSuccess = async (res: any) => {
    res?.authToken && await StorageSrvc.setItem(STORAGE_SRVC_KEYS.AUTH_TOKEN, res.authToken);
    res?.refreshToken && await StorageSrvc.setItem(STORAGE_SRVC_KEYS.REFRESH_TOKEN, res.refreshToken);
    await StorageSrvc.setItem(STORAGE_SRVC_KEYS.CURRENT_USER, res);
    queryClient?.invalidateQueries({ queryKey: [AUTH_CONSTANTS.QUERY_KEYS.STORAGE_PROFILE] });
    setCurrentUser(res);
    callback(res);
    setIsOpen(false);
  }

  const gSignError = (err: any) => {
    console.log(err);
    setAuthError(getErrorStringFromObject(signinUserMutation.error) || 'Error signing in with Google');
    errorCallback && errorCallback(err);
  };

  const renderLoginForm = () => {
    return (
      <div onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-brand-lightgray mb-1">
            Email address
          </label>
          <div className="relative">
            <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-brand-lightgray w-5 h-5" />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-brand-lightgray focus:border-brand-lightgray"
              placeholder="you@example.com"
              required
              disabled={signinUserMutation.status === 'pending'}
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-brand-lightgray mb-1">
            Password
          </label>
          <div className="relative">
            <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-brand-lightgray w-5 h-5" />
            <input
              autoComplete='new-password'
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-brand-lightgray focus:border-brand-lightgray"
              placeholder="Enter your password"
              required
              disabled={signinUserMutation.status === 'pending'}
            />
          </div>
        </div>

        {mode === 'login' && (
          <div className="flex items-center justify-between text-sm">
            <button
              type="button"
              className="text-brand-fadegray hover:text-brand-lightgray"
              onClick={() => setMode('forgot-password')}
            >
              Forgot password?
            </button>
          </div>
        )}

        <button
          type="submit"
          className={`w-full bg-brand-darkgray boder-brand-fadegray border-2 ${signinUserMutation.status === 'pending' ? 'text-brand-fadegray' : 'text-white'} px-4 py-2
              rounded-md hover:bg-brand-lightgray/10 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors`}
          onClick={handleSubmit}
          disabled={signinUserMutation.status === 'pending'}
        >
          {mode === 'login' ? (
            signinUserMutation.status === 'pending' && <span>Signing in ...</span> || <span>Sign in</span>
          ) : 'Create account'}

        </button>
      </div>
    );
  }

  const renderSignupStep = () => {
    switch (signupStep) {
      case 'email':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-brand-lightgray mb-1">
                Email address
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="email"
                  value={form.email}
                  onChange={(v) => handleInputChanges('email', v.target.value)}
                  className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 
                    focus:border-indigo-500 ${signupErrors.email ? 'border-red-500' : 'border-gray-300'
                    }`}
                  placeholder="you@example.com"
                  required
                />
              </div>
              {signupErrors.email && (
                <p className="mt-1 text-sm text-red-600">{signupErrors.email}</p>
              )}
            </div>
            <button
              onClick={handleEmailSubmit}
              className={`w-full bg-brand-darkgray boder-brand-fadegray border-2 ${sendOtpMutation.status === 'pending' ? 'text-brand-fadegray' : 'text-white'} px-4 py-2
              rounded-md hover:bg-brand-lightgray/10 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors`} >
              Send OTP
            </button>
          </div>
        );

      case 'otp':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-brand-lightgray mb-1">
                Enter OTP sent to {form.email}
              </label>
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  value={form.otp}
                  onChange={(v) => handleInputChanges('otp', v.target.value)}
                  className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${signupErrors.otp ? 'border-red-500' : 'border-gray-300'
                    }`}
                  placeholder="Enter 6-digit OTP"
                  maxLength={6}
                  required
                />
              </div>
              {signupErrors.otp && (
                <p className="mt-1 text-sm text-red-600">{signupErrors.otp}</p>
              )}
            </div>
            <button
              onClick={handleOTPSubmit}
              className={`w-full bg-brand-darkgray boder-brand-fadegray border-2 ${verifyOtpMutation.status === 'pending' ? 'text-brand-fadegray' : 'text-white'} px-4 py-2
              rounded-md hover:bg-brand-lightgray/10 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors`} >
              Verify OTP
            </button>
          </div>
        );

      case 'details':
        return (
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-brand-lightgray mb-1">
                  First Name
                </label>
                <div className="relative">
                  <User className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                  <input
                    type="text"
                    autoComplete='one-time-code'
                    value={form.firstName}
                    onChange={(v) => handleInputChanges('firstName', v.target.value)}
                    className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 
                      focus:border-indigo-500 ${signupErrors.firstName ? 'border-red-500' : 'border-gray-300'
                      }`}
                    required
                  />
                </div>
                {signupErrors.firstName && (
                  <p className="mt-1 text-sm text-red-600">{signupErrors.firstName}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-brand-lightgray mb-1">
                  Last Name
                </label>
                <div className="relative">
                  <User className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                  <input
                    type="text"
                    autoComplete='one-time-code'
                    value={form.lastName}
                    onChange={(v) => handleInputChanges('lastName', v.target.value)}
                    className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 
                      focus:border-indigo-500 ${signupErrors.lastName ? 'border-red-500' : 'border-gray-300'
                      }`}
                    required
                  />
                </div>
                {signupErrors.lastName && (
                  <p className="mt-1 text-sm text-red-600">{signupErrors.lastName}</p>
                )}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-brand-lightgray mb-1">
                Password
              </label>
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  autoComplete='new-password'
                  type="password"
                  value={form.password}
                  onChange={(v) => handleInputChanges('password', v.target.value)}
                  className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 
                    focus:border-indigo-500 ${signupErrors.password ? 'border-red-500' : 'border-gray-300'
                    }`}
                  required
                />
              </div>
              {signupErrors.password && (
                <p className="mt-1 text-sm text-red-600">{signupErrors.password}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-brand-lightgray mb-1">
                Confirm Password
              </label>
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  autoComplete='new-password'
                  type="password"
                  value={form.confirmPassword}
                  onChange={(v) => handleInputChanges('confirmPassword', v.target.value)}
                  className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 
                    focus:border-indigo-500 ${signupErrors.confirmPassword ? 'border-red-500' : 'border-gray-300'
                    }`}
                  required
                />
              </div>
              {signupErrors.confirmPassword && (
                <p className="mt-1 text-sm text-red-600">{signupErrors.confirmPassword}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-brand-lightgray mb-1">
                Organization (Optional)
              </label>
              <div className="relative">
                <Building className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  autoComplete='one-time-code'
                  type="text"
                  value={form.organization}
                  onChange={(v) => handleInputChanges('organization', v.target.value)}
                  className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 
                    focus:border-indigo-500 ${signupErrors.organization ? 'border-red-500' : 'border-gray-300'
                    }`}
                />
              </div>
            </div>
            {signupErrors.signup && (
              <p className="mt-1 text-sm text-red-600">{signupErrors.signup}</p>
            )}
            <button
              onClick={handleSignupSubmit}
              className={`w-full bg-brand-darkgray boder-brand-fadegray border-2 ${signupMutation.status === 'pending' ? 'text-brand-fadegray' : 'text-white'} px-4 py-2
              rounded-md hover:bg-brand-lightgray/10 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors`} >
              Create Account
            </button>
          </div>
        );

      case 'success':
        return (
          <div className="text-center py-8">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
              <Check className="h-6 w-6 text-green-600" />
            </div>
            <h3 className="text-lg font-medium text-brand-lightgray">
              Account Created Successfully!
            </h3>
            <p className="text-sm text-brand-lightgray mb-4">
              Signing in automatically...
            </p>
          </div>
        );
    }
  };

  if (!isOpen) {
    return (
      <button
        onClick={async () => {
          const user = await StorageSrvc.getItem(STORAGE_SRVC_KEYS.CURRENT_USER);
          if (user) {
            callback(user);
          } else {
            setIsOpen(true)
          }
        }
        }
        className={className}
      >
        {content}
      </button>
    );
  }

  return (
    <div className="fixed sm:right-4 sm:top-14 w-full sm:w-96 rounded-lg border border-2 border-white overflow-hidden shadow-gold ">
      <div className='fixed top-0 bottom-0 right-0 left-0 bg-brand-darkgray/90' onClick={() => {
        setIsOpen(false);
      }}></div>

      <div className="relative p-6 bg-brand-darkgray w-full max-w-96">
        <button
          onClick={() => setIsOpen(false)}
          className="absolute right-4 top-4 text-brand-lightgray hover:text-brand-lightgray transition-colors"
        >
          <X className="w-5 h-5" />
        </button>

        <p className="text-brand-lightgray font-bold mb-6">
          {mode === 'login'
            ? 'Sign in to continue to your account'
            : mode === 'register' ? 'Get started with your account' : ''}
        </p>
        {
          !currentUser &&
          (mode === 'login' || mode === 'register') &&
          <>
            <GoogleSignIn successLoginCallback={gSignSuccess} errorCallback={gSignError} />

            <div className="relative my-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-brand-darkgray text-brand-lightgray">Or continue with</span>
              </div>
            </div>
          </>
        }

        {mode === 'login' ? renderLoginForm() : mode === "forgot-password" ? <ForgotPassword onBack={() => setMode('login')} /> : renderSignupStep()}

        {!currentUser && mode !== "forgot-password" && <p className="mt-4 text-center text-sm text-brand-fadegray">
          {mode === 'login' ? "Don't have an account?" : 'Already have an account?'}
          <button
            onClick={() => setMode(mode === 'login' ? 'register' : 'login')}
            className="ml-1 text-brand-lightgray font-medium"
          >
            {mode === 'login' ? 'Sign up' : 'Sign in'}
          </button>
        </p>}

        {authError && mode!== 'forgot-password' && (
          <div className="mt-4 text-center text-sm text-red-500">
            {authError}
          </div>
        )}
      </div>

    </div>
  );
}