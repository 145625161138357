import { MUTATIONS as AUTH_MUTATIONS, QUERIES as AUTH_QUERIES } from "./AuthApi";
import { MUTATIONS as RSVP_MUTATIONS, QUERIES as RSVP_QUERIES } from "./RsvpApi";
import { MUTATIONS as OPEN_MUTATIONS, QUERIES as OPEN_QUERIES } from "./OpenApi";

export default {
  QUERIES: {
    AUTH: AUTH_QUERIES,
    RSVP: RSVP_QUERIES,
    OPEN: OPEN_QUERIES
  },

  MUTATIONS: {
    AUTH: AUTH_MUTATIONS,
    RSVP: RSVP_MUTATIONS,
    OPEN: OPEN_MUTATIONS
  }
}

